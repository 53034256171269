import React from "react";
import PropTypes from "prop-types";
import BlockContent from "@sanity/block-content-to-react";

const BlockRenderer = props => {
  const { style = "normal" } = props.node;

  if (style === "normal") {
    return <p style={{ margin: "12px 0 0" }}>{props.children}</p>;
  }

  return BlockContent.defaultSerializers.types.block(props);
};

export const SpanRenderer = ({ children }) => {
  return children;
};

const defaultSerializers = {
  types: {
    block: BlockRenderer,
  },
  marks: {
    internalLink: SpanRenderer,
    externalLink: SpanRenderer,
  },
};

const truncateBlocks = (blocks, maxCharacters) => {
  let textLength = 0;
  blocks[0].children = blocks[0].children.reduce((acc, child) => {
    console.log(child);
    if (textLength < maxCharacters) {
      if (textLength + child.text.length > maxCharacters) {
        const slicedText = child.text.slice(0, maxCharacters - textLength);
        const lastSpaceIndex = slicedText.lastIndexOf(" ");
        child.text =
          lastSpaceIndex > 0 ? slicedText.slice(0, lastSpaceIndex) + "…" : "…";
      }
      acc.push(child);
      textLength += child.text.length;
    }
    return acc;
  }, []);
  return [blocks[0]];
};

const LeadParagraph = ({ blocks, maxCharacters }) => {
  const maybeTruncatedBlock =
    maxCharacters === Number.POSITIVE_INFINITY
      ? blocks
      : truncateBlocks(blocks, maxCharacters);
  return (
    <BlockContent
      blocks={maybeTruncatedBlock}
      serializers={defaultSerializers}
      projectId="w3tlk96v"
      dataset="production"
    />
  );
};

LeadParagraph.defaultProps = {
  maxCharacters: Number.POSITIVE_INFINITY,
};

LeadParagraph.propTypes = {
  blocks: PropTypes.array.isRequired,
  maxCharacters: PropTypes.number,
};

export default LeadParagraph;
