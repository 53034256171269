import React, { Component } from "react";
import Layout from "../components/Layout";

const GraphQLErrorList = ({ errors }) => (
  <div>
    <h1>GraphQL Error</h1>
    {errors.map(error => (
      <pre key={error.message}>{error.message}</pre>
    ))}
  </div>
);

const withGraphQLErrors = () => WrappedComponent => {
  return class withGraphQLErrorsHOC extends Component {
    render() {
      const { errors } = this.props;

      if (errors) {
        return (
          <Layout>
            <GraphQLErrorList errors={errors} />
          </Layout>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withGraphQLErrors;
