import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./SectionLayout.module.scss";

const cx = classNames.bind(styles);

const SectionLayout = ({ children, backgroundColor, id }) => {
  const isRed =
    backgroundColor &&
    (backgroundColor.value === "#d05259" ||
      backgroundColor.value === "#ce474e");
  const isDark =
    backgroundColor &&
    backgroundColor.value !== "#fff" &&
    backgroundColor.value !== "#ebebe9";

  const sectionClassNames = cx({
    section: true,
    isRed,
    isBlue: backgroundColor && backgroundColor.value === "#414d5f",
    isWhite:
      !backgroundColor ||
      backgroundColor.value === "#fff" ||
      backgroundColor.value === "#ebebe9",
    isDark,
  });
  return (
    <div
      id={id}
      className={`${sectionClassNames} ${isDark ? "is-dark" : ""}`}
      style={{ backgroundColor: backgroundColor && backgroundColor.value }}
    >
      {children}
    </div>
  );
};

SectionLayout.defaultProps = {
  id: null,
  backgroundColor: null,
};

SectionLayout.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.shape({ value: PropTypes.string }),
  id: PropTypes.string,
};

export default SectionLayout;
