import React, { useRef } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { getUrlFor } from "../utils/imageUtils";
import LeadParagraph from "../primitives/LeadParagraph";
import ReverseOrderLayout from "../primitives/ReverseOrderLayout";
import styles from "./ProjectTile.module.scss";

const ProjectTile = ({
  slug,
  title,
  subtitle,
  leadParagraph,
  thumbnail,
  backgroundColor,
}) => {
  const linkRef = useRef(null);
  const thumbnailUrl =
    thumbnail &&
    getUrlFor(thumbnail)
      .width(528)
      .height(528)
      .fit("crop")
      .url();

  const handleClick = evt => {
    if (
      linkRef.current &&
      linkRef.current !== evt.target &&
      document.getSelection().isCollapsed
    ) {
      linkRef.current.click();
    }
  };

  if (!slug) {
    return (
      <div
        className={styles.containerConfidential}
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
      />
    );
  }

  return (
    <article
      className={`${styles.container} ${
        leadParagraph ? styles.hasLeadParagraph : ""
      }`}
      style={{
        backgroundImage: thumbnailUrl ? `url(${thumbnailUrl})` : null,
        backgroundColor: backgroundColor ? backgroundColor.value : null,
      }}
      onClick={handleClick}
      role="presentation"
    >
      {subtitle && (
        <header>
          <ReverseOrderLayout>
            <h1 className={styles.title}>
              <Link to={slug} ref={linkRef}>
                {title}
              </Link>
            </h1>
            <p className={styles.subtitle}>{subtitle}</p>
          </ReverseOrderLayout>
        </header>
      )}
      {!subtitle && (
        <h1 className={styles.title}>
          <Link to={slug} ref={linkRef}>
            {title}
          </Link>
        </h1>
      )}
      {leadParagraph && (
        <div className={styles.leadParagraph}>
          <LeadParagraph blocks={leadParagraph} maxCharacters={90} />
        </div>
      )}
    </article>
  );
};

export const query = graphql`
  fragment SanityProjectFragment on SanityProject {
    _type
    title
    subtitle
    slug {
      current
    }
    thumbImage {
      ...SanityImageFragment
    }
  }
  fragment SanityPublicationFragment on SanityPublication {
    _type
    title
    subtitle
    category
    slug {
      current
    }
    backgroundColor {
      value
    }
    thumbImage {
      ...SanityImageFragment
    }
    _rawLeadParagraph(resolveReferences: { maxDepth: 10 })
  }
`;

ProjectTile.defaultProps = {
  slug: null,
  subtitle: null,
  thumbnail: null,
  backgroundColor: null,
};

ProjectTile.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  thumbnail: PropTypes.object,
  backgroundColor: PropTypes.object,
};

export default ProjectTile;
