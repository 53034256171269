import React from "react";
import PropTypes from "prop-types";
import styles from "./ReverseOrderLayout.module.scss";

const ReverseOrderLayout = ({ children }) => {
  return <div className={styles.component}>{children}</div>;
};

ReverseOrderLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ReverseOrderLayout;
